import { ReactElement, useEffect } from 'react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { NextPageWithLayout } from './_app'
import {
  getCategories,
  getMobileCategories,
} from '../api/categories/categories'
import { CategoryPaginationResponse } from '../types/categories/responses/category-pagination-response'
import Layout from '../components/layout'
import { DefaultSeo } from 'next-seo'
import SEO from '../next-seo.config'
import {
  GameTeammatesV2,
  getGamingTeammatesV2,
} from '../api/creators/getGamingTeammates'
import { CategoryResponse } from '../types/categories/responses/category-response'
import { HomeModel } from '../components/home/HomeModel'
import { GameCategories } from '../common/enums/game-categories'
import { useRecoilState } from 'recoil'
import { selectedGameState } from '../common/atoms/atoms'
import { selectedProductState } from '../common/atoms/checkout'
import { getRevalidateInterval } from '../common/utils/common.utils'

const Home: NextPageWithLayout<{
  categories: CategoryPaginationResponse
  seoCategory?: CategoryResponse
  gameTeammates: GameTeammatesV2
}> = ({ categories, seoCategory, gameTeammates }) => {
  const [, setGame] = useRecoilState(selectedGameState)
  const [, setProduct] = useRecoilState(selectedProductState)

  useEffect(() => {
    if (seoCategory) {
      setGame(seoCategory)
      if (seoCategory.products?.[0]) {
        setProduct(seoCategory.products[0])
      }
    }
  }, [seoCategory, setGame, setProduct])

  return (
    <>
      <DefaultSeo
        {...SEO}
        title={'Zero-Wait Teammate Finder | LoL, Valorant & Fortnite LFG'}
        description={
          'Find your perfect match with our Teammate Finder. Get paired with LoL Challenger teammates, Valorant Radiant teammates, and Fortnite Unreal teammates instantly. No more waiting, start winning now!'
        }
        canonical={'https://tapin.gg/'}
      />

      <HomeModel
        title={undefined}
        description={undefined}
        seoCategory={seoCategory}
        categories={categories}
        gameTeammates={gameTeammates}
        isHome={true}
      />
    </>
  )
}

Home.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>
}

type Props = {
  locale: string

  params: any
}

export async function getStaticProps({ locale, params }: Props) {
  const categories = await getCategories()
  await getMobileCategories()

  const gameTeammates = await getGamingTeammatesV2()

  if (categories?.data) {
    categories?.data?.forEach((category) => {
      category.products = category.products
        ?.filter((product) => product.hidden !== true)
        ?.sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0) || a.id - b.id)

      category.products?.forEach((product) => {
        product.productAddons = product.productAddons?.sort(
          (a, b) => (a.sort ?? 0) - (b.sort ?? 0) || a.id - b.id,
        )
      })
    })
  }

  const seoCategory = categories?.data?.find((item) => {
    return item.key === GameCategories.leagueOfLegends
  })

  const initialRecoilState = {
    game: seoCategory,
    product: seoCategory?.products?.[0],
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'common',
        'booking',
        'inGameModal',
        'promocodes',
        'blocklist',
      ])),
      categories: categories,
      seoCategory: seoCategory,
      gameTeammates: gameTeammates,
      initialRecoilState,
    },
    revalidate: getRevalidateInterval(),
  }
}

export default Home
